import React from "react"
import { graphql } from "gatsby"
import { makeStyles } from '@material-ui/core/styles';
import Layout from "../components/layout"
import { 
  Container, 
  Card,
  CardHeader,
  CardContent,
  Divider
 } from '@material-ui/core';
import SEO from "../components/seo"

import stepDefinitions from "../components/stepDefinitions"
import theme from "../components/theme"

const useStyles = makeStyles((theme) => ({
    step: {
      '& .bringHeader':     {
        ...theme.typography.h2,
        color: theme.palette.secondary.main
      },
      '& .bringItem'  :     theme.typography.body1,
      '& .receiveHeader':   {
        ...theme.typography.h2,
        color: theme.palette.secondary.main
      },
      '& .receiveItem' :    theme.typography.body1,
      '& .descriptionHeader': {
        ...theme.typography.h2,
        color: theme.palette.secondary.main
      },
      '& .description':     {
        ...theme.typography.body1,
        '& h2': theme.typography.h6,
      },
      '& .tip':             {
        ...theme.typography.body1, 
        color: theme.palette.secondary.main
      },
      '& a': {
        ...theme.typography.body1,
        color: theme.palette.primary.main
      },
      '& a.selfScheme': {
        display: 'none'
      },
      '& .tipContainer': {
        padding: 24,
        borderRadius: 0,
        borderRightColor: theme.palette.secondary.main,
        borderRightWidth: 6,
        borderRightStyle: 'solid',
        border: `1px solid rgba(0, 0, 0, 0.12)`,
      }
    },
  }),{defaultTheme: theme});

interface StepTemplateProps {
  data: {
    allHtmlContent: {
      nodes: {
        name: string,
        content: string
      }[] 
    }
  } 
  path: string
}

export default function StepTemplate(props: StepTemplateProps) {
  const { data, path } = props
  const stepContent = data.allHtmlContent.nodes[0].content
  const stepName = data.allHtmlContent.nodes[0].name
  const stepInfo = stepDefinitions.he_IL.content[stepName]
  const classes = useStyles();
  return (
      <Layout showNav={true} path={path}>
        <SEO 
          title={stepInfo.title}
          description={stepInfo.description}
        />
        <Container>
          <Card style={{marginTop: "24px", marginBottom: "24px"}}>
            <CardHeader title={stepInfo.title} titleTypographyProps={{variant: "h4"}}/>
            <Divider/>
            <CardContent>
              <div dangerouslySetInnerHTML={{__html: stepContent}} className={classes.step}></div>
            </CardContent>
          </Card>
        </Container>
      </Layout>
    
  )
}

export const pageQuery = graphql`
  query($relativeDirectory: String!) {
    allHtmlContent(filter: { name: { eq: $relativeDirectory } }) {
      nodes {
        name
        content
      }
    }
  }
`
